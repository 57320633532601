import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import React from 'react';

import Logo from './logo.png';

const App = () => (
  <Box
    component="main"
    sx={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      backgroundColor: '#fdf9ed',
      flexDirection: 'column',
    }}
  >
    <Container
      maxWidth="xs"
      sx={{
        display: 'flex',
        backgroundColor: '#fdf9ed',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        marginBottom: 3
      }}
    >
      <Stack spacing={1}>
        <Box
          component="img"
          src={Logo}
        />

        <Stack spacing={3}>
          <Typography variant="h2">PURE SMAKEN</Typography>
          <Divider sx={{ borderWidth: '1px'}} />

          <Typography variant="h6">WELKOM</Typography>
          <Typography variant="body1">
            Pure smaken, een plek waar sfeer en kwaliteit samenkomen voor een unieke beleving.
          </Typography>
          <Typography variant="body1">
            Pure smaken is een verborgen culinaire ervaring die niet te vinden is via de gewone kanalen.
            Zonder advertenties, bord aan de deur of zichtbaarheid op sociale media, blijft het een goed bewaard geheim onder fijnproevers.
          </Typography>
          <Typography variant="body1">
            Reserveringen zijn alleen beschikbaar via persoonlijke uitnodiging of aanbeveling, en de locatie wordt pas vlak voor de ervaring onthuld.
          </Typography>
          <Typography variant="body1">
            Dit is een plek voor de echte liefhebber, die op zoek is naar een unieke en exclusieve culinaire beleving.
          </Typography>
          <Typography variant="body1">
            
          </Typography>
        </Stack>
      </Stack>

      <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Box          
          sx={{ 
            border: '10px solid black',            
          }}
        >
          <Box
            component="img"
            src={Logo}
            sx={{ 
              maxHeight: '100px',
              maxWidth: '100px'
            }}
          />
        </Box>

        <Typography variant="h4">PURE SMAKEN</Typography>
        <Typography variant="subtitle1">WAAR VERFIJNING EN EXCLUSIVITEIT U ONTMOETEN</Typography>
      </Stack>
    </Container>
  </Box>
)

export default App;
